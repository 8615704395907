import {
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Flex,
  Heading,
  Input,
  Stack,
  Text,
  useColorModeValue,
  Link,
  useTheme,
  useToast
} from "@chakra-ui/react";
import { Link as ReactLink } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";

export default function ForgotPasswordForm() {
  const theme = useTheme();
  const toast = useToast();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: new Yup.object({
      email: Yup.string().email("Invalid email").required("Please enter email"),
    }),
    onSubmit: (values, { setSubmitting, resetForm}) => {
      const url = `${process.env.REACT_APP_API_URL}/client/auth/resetlink`;
      axios
        .post(url, values)
        .then(({data}) => {
          toast({
            title: "Success",
            description: data.message,
            status: "success",
            position: "bottom-right",
          });
          setSubmitting(false)
          resetForm()
        })
        .catch((err) => {
          toast({
            title: "Error",
            description: err.response.data.error.message,
            status: "error",
            position: "bottom-right",
          });
          setSubmitting(false);
        });
    },
  });
  return (
    <Flex minH={"80vh"} align={"center"} justify={"center"}>
      <form onSubmit={formik.handleSubmit}>
      <Stack
        spacing={4}
        w={["xs", "sm", "lg"]}
        bg={useColorModeValue("white", "gray.700")}
        rounded={"xl"}
        boxShadow={"sm"}
        p={6}
        my={12}>
        <Heading lineHeight={1.1} fontSize={{ base: "xl" }} textAlign="center">
          Forgot your password?
        </Heading>
        <Text fontSize={"sm"} color={"slate.400"} textAlign="center">
          You&apos;ll get an email with a reset link
        </Text>

        <FormControl
                isInvalid={!!formik.errors.email && formik.touched.email}>
                <FormLabel fontSize={"sm"}>Email address</FormLabel>
                <Input
                  name="email"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  borderColor="slate.200"
                  _focus={{
                    borderColor: "brand.primary",
                    boxShadow: `0 0 0 1px ${theme.colors.brand.primary}`,
                  }}
                />
                <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
              </FormControl>

        <Stack spacing={6}>
          <Button
          isLoading={formik.isSubmitting}
            loadingText="Please Wait..."
            bg="brand.primary"
            color="white"
            variant="solid"
            type="submit"
            _hover={{ background: theme.colors.brand.primaryDark }}
            _active={{ background: theme.colors.brand.primaryDark }}>
            Request Reset
          </Button>
        </Stack>
        <Stack pt={6}>
          <Text align={"center"} fontSize={"sm"}>
            Already a user?{" "}
            <Link color={"brand.primary"} as={ReactLink} to="/auth/signin">
              Login
            </Link>
          </Text>
        </Stack>
      </Stack>
      </form>
    </Flex>
  );
}
