import React from "react";
import {
  IconButton,
  Avatar,
  Box,
  Flex,
  HStack,
  VStack,
  useColorModeValue,
  Text,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useToast,
  
  Image,
} from "@chakra-ui/react";
import { FiMenu, FiChevronDown } from "react-icons/fi";
import { signout } from "../../helper/auth";
import { useUser } from "../../store/userState";
import { Link as ReLink, useNavigate } from "react-router-dom";
import LogoImage from "../../asset/image/logo.png";

export default function MobileNav({ onOpen, ...rest }) {
  const navigate = useNavigate();
  const { setUser, user } = useUser();
  const toast = useToast();

  //Duplicated in Header
  const handleSignout = () => {
    signout(() => {
      toast({
        description: "You have been logged out",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom-right",
      });
      setUser(null);
      navigate("/");
    });
  };

  return (
    <>
      

      <Flex
        ml={{ base: 0, md: 60 }}
        px={{ base: 4, md: 4 }}
        height="20"
        alignItems="center"
        bg={"white"}
        borderBottomWidth="1px"
        borderBottomColor={"slate.200"}
        justifyContent={{ base: "space-between", md: "flex-end" }}
        {...rest}>
        <IconButton
          display={{ base: "flex", md: "none" }}
          onClick={onOpen}
          variant="ghost"
          size={"lg"}
          color={"brand.primaryDark"}
          icon={<FiMenu />}
        />

        <Box display={{ base: "flex", md: "none" }}>
          <ReLink to={"/"}>
            <Image src={LogoImage} />
          </ReLink>
        </Box>

       
        <HStack spacing={{ base: "0", md: "6" }}>
          <Flex alignItems={"center"}>
            <Menu>
              <MenuButton
                py={2}
                transition="all 0.3s"
                _focus={{ boxShadow: "none" }}>
                <HStack>
                  <Avatar
                    size={"sm"}
                    name={user?.name}
                    bg="brand.primaryDark"
                    color={"white"}
                    // src={"https://api.adorable.io/avatars/285/"}
                  />
                  <VStack
                    display={{ base: "none", md: "flex" }}
                    alignItems="flex-start"
                    spacing="1px"
                    ml="2">
                    <Text fontSize="sm">{user?.name}</Text>
                    <Text fontSize="xs" color="gray.600">
                      {user?.email}
                    </Text>
                  </VStack>
                  <Box display={{ base: "none", md: "flex" }}>
                    <FiChevronDown />
                  </Box>
                </HStack>
              </MenuButton>
              <MenuList
                bg={useColorModeValue("white", "gray.900")}
                borderColor={useColorModeValue("gray.200", "gray.700")}
                zIndex={110}
                >
                <MenuItem onClick={() => navigate("/console/profile")}>
                  Profile
                </MenuItem>
                <MenuItem onClick={() => navigate("/console/profile/password")}>
                  Change Password
                </MenuItem>
                <MenuDivider />
                <MenuItem onClick={handleSignout}>Sign out</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </HStack>
      </Flex>
    </>
  );
}
