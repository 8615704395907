export const Activities = [
  { label: "Sedentary (Little or no exercise)", value: "sedentary" },
  { label: "Light (Exercise 1-3 times/week)", value: "light" },
  { label: "Moderate (Exercise 4-5 times/week)", value: "moderate" },
  {
    label: "Active (Daily exercise or intense exercise 3-4 times/week)",
    value: "active",
  },
  {
    label: "Extreme (Very intense exercise daily or physical job)",
    value: "extreme",
  },
];
