import React, {useMemo} from 'react';
import { Stack, Text, VStack, Container } from "@chakra-ui/react";
import {
      BMI,
      roundToTwoDecimal,
      BFP,
      idealBodyWeight,
      BMR_Mifflin
} from './fitness-calculator-free';

const BasicResult = ({values}) => {

      const data = useMemo(() => {
            const age = +values.age,
              height = +values.height,
              weight = +values.weight,
              waist = +values.waist,
              hip = +values.hip,
              neck = +values.neck,
              gender = values.gender;

            const bmi = BMI(height, weight),
              bfp = BFP(gender, height, weight, neck, waist, hip),
              bmr = roundToTwoDecimal(BMR_Mifflin(gender, age, height, weight)),
              ibw = roundToTwoDecimal(idealBodyWeight(gender, height));

            return { bmi, bmr, bfp, ibw, weight };
          }, [values]);

  return (
<Container maxW={"lg"} py={2}>
      <WeightResult weight={data.weight} ibw={data.ibw} />
      <ShowData label="Ideal body weight" value={`${data.ibw} Kg`} />
      <ShowData
        label="Body Mass Index (BMI)"
        value={data.bmi}
        reference={"Healthy BMI Range 18.5 - 25"}
      />
      <ShowData
        label="Basal Metabolic Rate (BMR)"
        value={`${data.bmr} Calories/Day`}
        reference="Mifflin-St Jeor Method"
      />
      <ShowData
        label="Body Fat Percentage"
        value={`${data.bfp}%`}
        reference={"Normal men 14% - 24%"}
        secondref={"Normal women 21% - 31%"}
        />
</Container>
)}

const WeightResult = ({ weight, ibw }) => {
      const resultWeight = +(weight - ibw).toFixed(2);
      return (
        <VStack
          p={5}
          color={"brand.primary"}
          rounded={"md"}
          border={(theme) => `3px solid ${theme.colors.brand["primary"]}`}>
          <Text fontSize={"xl"} fontWeight={"bold"}>
            {resultWeight > 0 ? "Reduce" : "Increase"}
          </Text>
          <Text fontSize={"2xl"} fontWeight={"bold"}>{`${Math.abs(
            resultWeight
          )} Kg`}</Text>
        </VStack>
      );
    };
    const ShowData = ({ label, value, reference, secondref }) => (
      <Stack
        justify={"space-between"}
        py={4}
        align={"center"}
        direction={{ base: "column", md: "row" }}>
        <Text fontWeight={"semibold"}>{label}</Text>
        <VStack
          align={{ base: "center", md: "flex-end" }}
          spacing={0}
          color={"brand.primary"}>
          <Text fontWeight={"semibold"}>{value}</Text>
          {reference && <Text fontSize={"sm"}>{reference}</Text>}
          {secondref && <Text fontSize={"sm"}>{secondref}</Text>}
        </VStack>
      </Stack>
    );

export default BasicResult