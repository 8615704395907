import {
  Box,
  chakra,
  Container,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
  Link,
} from "@chakra-ui/react";
import { Link as ReLink } from "react-router-dom";
import { FaInstagram, FaFacebook, FaYoutube } from "react-icons/fa";

const SocialButton = ({ children, label, href, target }) => {
  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      target={target}
      href={href}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}>
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function SmallWithLogoLeft() {
  return (
    <Box bg={"gray.900"} color={"slate.400"} pt={4}>
      <Container
        as={Stack}
        maxW={"6xl"}
        py={4}
        direction={{ base: "column", md: "row" }}
        spacing={4}
        justify={{ base: "center", md: "space-between" }}
        align={{ base: "center", md: "flex-start" }}
        fontSize={"sm"}>
        <Box>
          <Text pb={4} textAlign={{ base: "center", md: "left" }}>
            Email:{" "}
            <Link isExternal href="mailto:support@tdspro.in">
              support@tdspro.in
            </Link>
          </Text>
          <Text textAlign={{ base: "center", md: "left" }}>
            Tamil Diet Studio
            <br />
            21, Paramasivam Street, Senthaneer Puram, <br />
            Trichy, Tamilnadu - 620004
          </Text>
        </Box>
        <Box>
          <Text pb={2}>
            <Link as={ReLink} to={"/terms"}>
              Privacy policy &amp; terms of use
            </Link>
          </Text>
          <Stack
            direction={"row"}
            spacing={6}
            justifyContent={{ base: "center" }}>
            <SocialButton
              target={"_blank"}
              label={"Facebook"}
              href={
                "https://www.facebook.com/Tamil-Diet-Studio-101363188008494/"
              }>
              <FaFacebook />
            </SocialButton>
            <SocialButton
              target={"_blank"}
              label={"YouTube"}
              href={"https://www.youtube.com/@tamildietstudio"}>
              <FaYoutube />
            </SocialButton>
            <SocialButton
              target={"_blank"}
              label={"Instagram"}
              href={"https://www.instagram.com/tamildietstudio/"}>
              <FaInstagram />
            </SocialButton>
          </Stack>
        </Box>
        <Box>
          <Text pb={2}>
            <Link as={ReLink} to={"/terms"}>
              Privacy policy &amp; terms of use
            </Link>
          </Text>
          <Text pb={2}>
            Web app by{" "}
            <Link href={"https://pugazhenthi.in/"} isExternal>
              https://pugazhenthi.in
            </Link>
          </Text>
        </Box>
      </Container>
    </Box>
  );
}
