import React from "react";
import { Button, Link, VStack, HStack, Text } from "@chakra-ui/react";
import { FaWhatsapp, FaPhoneAlt } from "react-icons/fa";

const Enquiry = () => {
  return (
    <VStack py={12} justify={"center"} bg={"gray.50"} spacing={8}>
      <Text color={"gray.700"} fontWeight={"semibold"} fontSize={"lg"}>
        For Enquiry / Support +91 78716 05001
      </Text>
      <HStack>
        <Button
          as={Link}
          _hover={{ textDecoration: "none" }}
          isExternal={true}
          leftIcon={<FaWhatsapp />}
          href={
            "https://wa.me/917871605001?text=Hi%2C%20I%20want%20to%20know%20more%20about%20fat%20loss%20programme%20%20and%20join.%20Please%20call%20me%20back."
          }
          colorScheme={"teal"}
          size={"lg"}>
          Whatsapp
        </Button>
        <Button
          as={Link}
          _hover={{ textDecoration: "none" }}
          isExternal={true}
          leftIcon={<FaPhoneAlt />}
          href={"tel:+917871605001"}
          colorScheme={"messenger"}
          size={"lg"}>
          Call
        </Button>
      </HStack>
    </VStack>
  );
};

export default Enquiry;
