import { useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { isAuth } from "./helper/auth";
import { useUser } from "./store/userState";
import Auth from "./auth";
import Console from "./Console";
import Home from "./Home";
import Calculator from "./Calculator";
import Terms from "./Terms";
import PaymentStatus from "./payment";

const Protected = ({ children }) => {
  const { setUser } = useUser();
  
  useEffect(() => {
    setUser(isAuth());
  }, [setUser]);

  return !!isAuth() ? children : <Navigate to="/auth" />;
};

const AppRouter = () => {
  const { setUser } = useUser();
  useEffect(() => {
    setUser(isAuth());
  }, [setUser]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/auth/*" element={<Auth />} />
        <Route
          path="/console/*"
          element={
            <Protected>
              <Console />
            </Protected>
          }
        />
        <Route path="/calculator" element={<Calculator />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/payment" element={<PaymentStatus />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
