import AppRouter from "./Router";
import theme from "./theme";
import "./asset/style/index.scss";
import "./theme/style.css";
import Interceptor from "./Interceptor";
import { QueryClient, QueryClientProvider } from "react-query";
import { ChakraProvider } from "@chakra-ui/react";

const queryClient = new QueryClient();

const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <Interceptor>
        <QueryClientProvider client={queryClient}>
          <AppRouter />
        </QueryClientProvider>
      </Interceptor>
    </ChakraProvider>
  );
};

export default App;
