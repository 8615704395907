import React from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  useTheme,
  InputGroup,
  InputRightAddon,
  NumberInput,
  NumberInputField,
} from "@chakra-ui/react";

const WeightInput = ({ name, label, formik }) => {
  const theme = useTheme();

  return (
    <FormControl isInvalid={!!formik.errors[name] && formik.touched[name]}>
      <FormLabel fontSize={"sm"}>{label}</FormLabel>
      <InputGroup>
        <NumberInput
          inputMode={"decimal"}
          precision={2}
          step={1}
          min={0}
          max={250}
          w={"full"}
          name={name}
          value={formik.values[name]}
          onChange={(v) => formik.setFieldValue(name, v)}>
          <NumberInputField
            borderColor="slate.200"
            borderRightRadius={0}
            _focus={{
              borderColor: "brand.primary",
              boxShadow: `0 0 0 1px ${theme.colors.brand.primary}`,
            }}
          />
        </NumberInput>
        <InputRightAddon children="kg" />
      </InputGroup>
      <FormErrorMessage>{formik.errors[name]}</FormErrorMessage>
    </FormControl>
  );
};

export default WeightInput;
