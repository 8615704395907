import React from "react";
import Footer from "./components/shared/Footer";
import Header from "./components/shared/Header";
import Hero from "./components/Home/Hero";
import Testimonials from "./components/Home/Testimonials";
import Features from "./components/Home/Features";
import Pricing from "./components/Home/Pricing";
import Transformations from "./components/Home/Transformations";
import Enquiry from "./components/Home/Enquiry";

const Home = () => {
  return (
    <>
      <Header />
      <Hero />
      <Features />
      <Pricing />
      <Transformations />
      <Testimonials />
      <Enquiry />
      <Footer />
    </>
  );
};

export default Home;
