import React, { useState } from 'react'
import Header from "../components/shared/Header";
import Footer from "../components/shared/Footer";
import {Box} from "@chakra-ui/react";
import CalculatorForm from './CalculatorForm';
import BasicResult from './BasicResult';
import Calories from './Calories';

const CalculatorIndex = () => {
  const [result, setResult] = useState(null);
  const handleCalculatorSubmit = (values) => {
    setResult(values)
  }
  return (
    <Box bg={"gray.50"}>
    <Header />
    <Box>
      <CalculatorForm onSubmit={handleCalculatorSubmit}/>
    </Box>
    {result && <Box>
      <BasicResult values={result} />
      <Calories values={result} />
    </Box>}
    <Footer />
    </Box>
  )
}

export default CalculatorIndex