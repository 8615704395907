import { extendTheme, theme as base } from "@chakra-ui/react";
const theme = extendTheme({
  fonts: {
    heading: `'Poppins', sans-serif`,
    body: `'Poppins', sans-serif`,
  },
  colors: {
    slate: {
      50: "#f8fafc",
      100: "#f1f5f9",
      200: "#e2e8f0",
      300: "#cbd5e1",
      400: "#94a3b8",
      500: "#64748b",
      600: "#475569",
      700: "#334155",
      800: "#1e293b",
      900: "#0f172a",
    },
    purple: {
      50: "#faf5ff",
      100: "#f3e8ff",
      200: "#e9d5ff",
      300: "#d8b4fe",
      400: "#c084fc",
      500: "#a855f7",
      600: "#9333ea",
      700: "#7e22ce",
      800: "#6b21a8",
      900: "#581c87",
    },
    brand: {
      primary: "#6a2876",
      primaryDark: "#44185c",
      accent: "#e34a30",
      accentDark: "#c83f27",
      bg: base.colors.gray["50"],
      // bg: "rgba(172, 163, 178, 0.1)",
    },
    primary: {
      // 50: "#ecfdf5",
      // 100: "#d1fae5",
      // 200: "#a7f3d0",
      // 300: "#6ee7b7",
      // 400: "#34d399",
      // 500: "#10b981",
      // 600: "#059669",
      // 700: "#047857",
      // 800: "#065f46",
      // 900: "#064e3b",
      ...base.colors.teal,
    },
  },
});
export default theme;
