import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  InputGroup,
  HStack,
  InputRightElement,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Link,
  Select,
  useTheme,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { Link as ReactLink, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";

export default function SignupCard() {
  const [showPassword, setShowPassword] = useState(false);
  const toast = useToast();
  const theme = useTheme();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      name: "",
      gender: "",
      mobile: "",
      email: "",
      password: "",
    },
    validationSchema: new Yup.object({
      name: Yup.string()
        .trim()
        .required("Please enter name")
        .min(3, "Minimum 3 characters long"),
      gender: Yup.string().trim().required("Please select gender"),
      mobile: Yup.string()
        .trim()
        .required("Please enter mobile number")
        .min(10, "Please check mobile number")
        .max(13, "Please check mobile number"),
      // .matches(/^[6-9]\d{9}$/, "Enter 10 digit valid mobile number"),
      email: Yup.string().email("Invalid email").required("Please enter email"),
      password: Yup.string()
        .trim()
        .required("Please set password")
        .min(6, "Minimum 6 characters long"),
    }),
    onSubmit: (values, { setSubmitting }) => {
      const url = `${process.env.REACT_APP_API_URL}/client/auth/signup`;
      axios
        .post(url, values)
        .then(({ data }) => {
          setSubmitting(false);
          const options = {
            ...data,
            handler: (response) => {
              navigate("/payment?payment_status=Credit");
            },
            theme: {
              color: theme.colors.brand.primary,
            },
          };
          const rzp = new window.Razorpay(options);
          rzp.on("payment.failed", function (response) {
            rzp.close();
            navigate("/payment?payment_status=Failed");
          });
          rzp.open();
        })
        .catch((err) => {
          console.log(err);
          toast({
            title: "Error",
            description: err.response.data.error.message,
            status: "error",
            position: "bottom-right",
          });
          setSubmitting(false);
        });
    },
  });

  return (
    <Flex minH={"80vh"} align={"center"} justify={"center"}>
      <Stack spacing={8} mx={"auto"} w={["xs", "sm", "lg"]} py={12} px={6}>
        <Stack align={"center"}></Stack>
        <form onSubmit={formik.handleSubmit}>
          <Box
            rounded={"lg"}
            bg={useColorModeValue("white", "gray.700")}
            boxShadow={"sm"}
            p={8}
          >
            <Heading
              fontSize={{ base: "lg", md: "xl" }}
              textAlign={"center"}
              pb={2}
            >
              Create account
            </Heading>
            <Stack spacing={4}>
              <HStack>
                <FormControl
                  isInvalid={!!formik.errors.name && formik.touched.name}
                >
                  <FormLabel fontSize={"sm"}>Full Name</FormLabel>
                  <Input
                    type="text"
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    borderColor="slate.200"
                    _focus={{
                      borderColor: "brand.primary",
                      boxShadow: `0 0 0 1px ${theme.colors.brand.primary}`,
                    }}
                  />
                  <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                </FormControl>
              </HStack>

              <FormControl
                isInvalid={!!formik.errors.gender && formik.touched.gender}
              >
                <FormLabel fontSize={"sm"}>Gender</FormLabel>
                <Select
                  name="gender"
                  onChange={formik.handleChange}
                  value={formik.values.gender}
                  borderColor="slate.200"
                  _focus={{
                    borderColor: "brand.primary",
                    boxShadow: `0 0 0 1px ${theme.colors.brand.primary}`,
                  }}
                  placeholder="Gender"
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </Select>
                <FormErrorMessage>{formik.errors.gender}</FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={!!formik.errors.mobile && formik.touched.mobile}
              >
                <FormLabel fontSize={"sm"}>Mobile</FormLabel>
                <Input
                  name="mobile"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.mobile}
                  borderColor="slate.200"
                  _focus={{
                    borderColor: "brand.primary",
                    boxShadow: `0 0 0 1px ${theme.colors.brand.primary}`,
                  }}
                />
                <FormErrorMessage>{formik.errors.mobile}</FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={!!formik.errors.email && formik.touched.email}
              >
                <FormLabel fontSize={"sm"}>Email address</FormLabel>
                <Input
                  name="email"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  borderColor="slate.200"
                  _focus={{
                    borderColor: "brand.primary",
                    boxShadow: `0 0 0 1px ${theme.colors.brand.primary}`,
                  }}
                />
                <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={!!formik.errors.password && formik.touched.password}
              >
                <FormLabel fontSize={"sm"}>Set Password</FormLabel>
                <InputGroup>
                  <Input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    borderColor="slate.200"
                    _focus={{
                      borderColor: "brand.primary",
                      boxShadow: `0 0 0 1px ${theme.colors.brand.primary}`,
                    }}
                  />
                  <InputRightElement h={"full"}>
                    <Button
                      variant={"link"}
                      onClick={() =>
                        setShowPassword((showPassword) => !showPassword)
                      }
                    >
                      {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
              </FormControl>
              <Stack spacing={10} pt={2}>
                <Button
                  isLoading={formik.isSubmitting}
                  loadingText="Submitting"
                  size="lg"
                  variant={"solid"}
                  bg={"brand.primary"}
                  color="white"
                  _hover={(theme) => ({
                    background: theme.colors.brand.primaryDark,
                  })}
                  _active={(theme) => ({
                    background: theme.colors.brand.primaryDark,
                  })}
                  type="submit"
                >
                  Sign up
                </Button>
              </Stack>
              <Stack pt={6}>
                <Text align={"center"} fontSize={"sm"}>
                  Already a user?{" "}
                  <Link
                    color={"brand.primary"}
                    as={ReactLink}
                    to="/auth/signin"
                  >
                    Login
                  </Link>
                </Text>
              </Stack>
            </Stack>
          </Box>
        </form>
      </Stack>
    </Flex>
  );
}
