import React from "react";
import { Flex, Icon, Link } from "@chakra-ui/react";
import {
  FiPieChart,
  FiTrendingUp,
  FiCoffee,
  FiLogIn,
  FiVideo,
  // FiActivity,
  FiFolderPlus,
  FiCheckSquare,
  FiFile,
  FiHeadphones,
  FiEdit3,
  FiDatabase,
} from "react-icons/fi";
import { NavLink as ReactLink } from "react-router-dom";
import { useUser } from "../../store/userState";

const ConsoleMenuItems = [
  { name: "Dashboard", icon: FiPieChart, url: "/console/dashboard" },
  { name: "Weekly Progress", icon: FiTrendingUp, url: "/console/progress" },
  { name: "Daily Status", icon: FiCheckSquare, url: "/console/dailystatus" },
  { name: "Food & calories", icon: FiCoffee, url: "/console/food" },
  { name: "My Diet Plan", icon: FiEdit3, url: "/console/mydietplan" },
  { name: "Assigned Diet", icon: FiFile, url: "/console/assigned-diet" },
  { name: "Workout videos", icon: FiHeadphones, url: "/console/workoutvideos" },
  { name: "Videos", icon: FiVideo, url: "/console/video" },
  { name: "Blog", icon: FiDatabase, url: "/console/blog" },
  // { name: "Workout Log", icon: FiActivity, url: "/console/workout" },
  {
    name: "Add missing food",
    icon: FiFolderPlus,
    url: "/console/foodrequest",
  },
];

const PublicMenuItems = [
  { name: "Log in", icon: FiLogIn, url: "/auth/signin" },
];

export default function SidebarContent() {
  const { user } = useUser();
  return (
    <>
      {!user ? (
        <>
          {PublicMenuItems.map((link, index) => (
            <NavItem icon={link.icon} key={index} to={link.url}>
              {link.name}
            </NavItem>
          ))}
        </>
      ) : (
        <>
          {ConsoleMenuItems.map((link, index) => (
            <NavItem icon={link.icon} key={index} to={link.url}>
              {link.name}
            </NavItem>
          ))}
        </>
      )}
    </>
  );
}

const NavItem = ({ icon, children, to, ...rest }) => {
  return (
    <Link
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
      _active={{ bg: "red" }}
      as={ReactLink}
      to={to}
      {...rest}
      className="side-nav-item"
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "brand.primary",
          color: "white",
        }}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};
