import React, { useRef } from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  useTheme,
  InputGroup,
  InputRightAddon,
  NumberInput,
  NumberInputField,
  Select,
} from "@chakra-ui/react";

function inchToCm(inch) {
  return inch * 2.54;
}

const CmInchInput = ({ name, label, formik, display = "block" }) => {
  const theme = useTheme();

  const unitRef = useRef("cm");
  const valueRef = useRef();

  const handleChange = () => {
    const unit = unitRef.current.value;
    const value = +valueRef.current.value;

    const emit = unit === "cm" ? value : inchToCm(value);
    formik.setFieldValue(name, emit || "");
  };

  return (
    <FormControl
      isInvalid={!!formik.errors[name] && formik.touched[name]}
      display={display}>
      <FormLabel fontSize={"sm"}>{label}</FormLabel>
      <InputGroup>
        <NumberInput
          inputMode={"numeric"}
          precision={2}
          step={1}
          name={name}
          type="number"
          min={0}
          max={300}
          w={"full"}
          onChange={handleChange}
          defaultValue={formik.values[name]}>
          <NumberInputField
            ref={valueRef}
            borderColor="slate.200"
            borderRightRadius={0}
            _focus={{
              borderColor: "brand.primary",
              boxShadow: `0 0 0 1px ${theme.colors.brand.primary}`,
            }}
          />
        </NumberInput>
        <InputRightAddon
          border={0}
          p={0}
          children={
            <Select
              onChange={handleChange}
              ref={unitRef}
              p={0}
              m={0}
              borderWidth={0}
              _focus={{
                shadow: "none",
              }}>
              <option value="cm">cm</option>
              <option value="inch">inch</option>
            </Select>
          }
        />
      </InputGroup>
      <FormErrorMessage>{formik.errors[name]}</FormErrorMessage>
    </FormControl>
  );
};

export default CmInchInput;
