import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Text,
  Stack,
  Image,
} from "@chakra-ui/react";

import MealImage from "../../asset/image/meal.jpg";
import GroupImage from "../../asset/image/group.jpg";
import WorkoutImage from "../../asset/image/workout.jpg";
import WeightLoss from "../../asset/image/weightloss.jpg";

// Replace test data with your own
const features = [
  {
    id: 1,
    title: "You'll lose excess weight",
    image: WeightLoss,
    text: "You will unquestionably reduce weight in 90 days. Additionally, you can learn how to maintain your desired weight while losing weight.",
  },
  {
    id: 2,
    title: "Personalised Meal Plan",
    image: MealImage,
    text: "You can acquire a daily meal plan to lose weight healthily; in particular, our meal plan is safe for you and you can customise it.",
  },
  {
    id: 3,
    title: "Simple workouts",
    image: WorkoutImage,
    text: "To lose fat and maintain your optimum weight, you can learn basic exercises you can do at home.",
  },
  {
    id: 4,
    title: "Like-Minded Private Group",
    image: GroupImage,
    text: "We will be by your side during your entire weight loss journey, so you won't feel helpless. You can ask questions in a private Facebook group where Mr. Vijay will respond to you live.",
  },
];

export default function Features() {
  return (
    <Box
      px={4}
      py={24}
      borderBottom={"1px"}
      borderColor={"gray.200"}
      bg={"gray.50"}>
      <Stack spacing={4} as={Container} maxW={"3xl"} textAlign={"center"}>
        <Heading
          fontSize={{ base: "xl", md: "2xl", lg: "3xl", xl: "4xl" }}
          as={"h2"}
          pb={6}
          fontWeight={{ base: "bold", lg: "300" }}>
          How would this programme benefit you?
        </Heading>
      </Stack>

      <Container maxW={"6xl"} mt={10}>
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 4 }}
          spacing={4}
          justifyItems={"center"}>
          {features.map((feature) => (
            <Card data={feature} key={feature.id} />
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
}

function Card({ data }) {
  return (
    <Box
      bg={"white"}
      maxW="md"
      // borderWidth="1px"
      rounded="lg"
      shadow="sm"
      position="relative">
      <Image src={data.image} alt={data.title} roundedTop="lg" />

      <Box p="6">
        <Text fontWeight={"bold"} pb={6}>
          {data.title}
        </Text>
        <Text fontSize={"sm"}>{data.text}</Text>
      </Box>
    </Box>
  );
}
