import {
  Container,
  Heading,
  Stack,
  VStack,
  Text,
  Button,
  Box,
  Image,
} from "@chakra-ui/react";
import image from "../../asset/image/vijay-hero.png";
import { Link as ReLink } from "react-router-dom";
import { useUser } from "../../store/userState";

export default function CallToActionWithIllustration() {
  const { user } = useUser();

  return (
    <Box
      // bg={"brand.primaryDark"}
      className="app-hero">
      <Container maxW={"5xl"}>
        <Stack
          align={{ base: "center", md: "flex-end" }}
          justify={"center"}
          spacing={{ base: 8, md: 10 }}
          py={0}
          direction={{ base: "column", md: "row" }}>
          <VStack spacing={6} flex={1} py={24}>
            <Heading
              fontWeight={700}
              textAlign={"center"}
              fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
              color="white"
              lineHeight={"110%"}>
              Be the best version of you
            </Heading>
            <Text
              color={"white"}
              fontSize={{ base: "xl", md: "4xl" }}
              fontWeight={"300"}
              textAlign={"center"}>
              12-Week ultimate fat loss programme
            </Text>

            <Button
              as={ReLink}
              to={!user ? "/auth/signup" : "/console/dashboard"}
              rounded={"full"}
              px={6}
              color="#fff"
              bg={"brand.accent"}
              _hover={{ bg: "brand.accentDark" }}
              _active={{ bg: "brand.accentDark" }}>
              {!user ? "Signup now" : "Dashboard"}
            </Button>
          </VStack>

          <Box>
            <Image
              src={image}
              alt={"TDS PRO"}
              height={{ sm: "24rem", lg: "32rem" }}
            />
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}
