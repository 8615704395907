import {
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  Container,
  Avatar,
  useColorModeValue,
} from "@chakra-ui/react";

const Testimonial = ({ children }) => {
  return <Box>{children}</Box>;
};

const TestimonialContent = ({ children }) => {
  return (
    <Stack
      bg={"white"}
      boxShadow={"lg"}
      p={8}
      rounded={"xl"}
      align={"center"}
      pos={"relative"}
      _after={{
        content: `""`,
        w: 0,
        h: 0,
        borderLeft: "solid transparent",
        borderLeftWidth: 16,
        borderRight: "solid transparent",
        borderRightWidth: 16,
        borderTop: "solid",
        borderTopWidth: 16,
        borderTopColor: useColorModeValue("white", "gray.800"),
        pos: "absolute",
        bottom: "-16px",
        left: "50%",
        transform: "translateX(-50%)",
      }}>
      {children}
    </Stack>
  );
};

const TestimonialHeading = ({ children }) => {
  return (
    <Heading as={"h3"} fontSize={"md"} color="slate.700">
      {children}
    </Heading>
  );
};

const TestimonialText = ({ children }) => {
  return (
    <Text textAlign={"center"} color={"slate.600"} fontSize={"sm"}>
      {children}
    </Text>
  );
};

const TestimonialAvatar = ({ src, name, title }) => {
  return (
    <Flex align={"center"} mt={8} direction={"column"}>
      {!!src && <Avatar src={src} alt={name} mb={2} name={name} />}
      <Stack spacing={-1} align={"center"}>
        <Text fontWeight={600} fontSize={"sm"} color={"white"} mb={1}>
          {name}
        </Text>
        {!!title && (
          <Text fontSize={"xs"} color={"gray.50"}>
            {title}
          </Text>
        )}
      </Stack>
    </Flex>
  );
};

export default function WithSpeechBubbles() {
  const data = [
    {
      id: 1,
      heading: "Experience in fat loss",
      text: "Vijay Sir has extensive fitness and weight loss experience. He guided me the entire way I was trying to lose weight.",
      name: "Maheswari",
      title: null,
      photo: null,
    },
    {
      id: 2,
      heading: "Making me fit and healthy",
      text: "I've been a longtime subscriber to Mr. Vijay's channel and am grateful to him for making me fit and healthy because he has adequate understanding of diet and fitness.",
      name: "Priya",
      title: null,
      photo: null,
    },
    {
      id: 3,
      heading: "Nice personal trainer",
      text: "I finally lost 40Kg of weight while being constantly watched over by Mr. Vijay. He is a nice personal trainer. sincerely, I want to thank TDS.",
      name: "Karthik",
      title: null,
      photo: null,
    },
  ];
  return (
    <Box className="testimonial-wrapper">
      <Container maxW={"7xl"} py={16} as={Stack} spacing={12}>
        <Stack spacing={0} align={"center"}>
          <Heading size={{ base: "md", md: "lg" }} color={"white"}>
            Our Clients Speak
          </Heading>
          <Text color={"white"}>
            We have been working with clients around the world
          </Text>
        </Stack>
        <Stack
          direction={{ base: "column", md: "row" }}
          spacing={{ base: 10, md: 4, lg: 10 }}>
          {data.map((item) => (
            <Testimonial key={item.id}>
              <TestimonialContent>
                <TestimonialHeading>{item.heading}</TestimonialHeading>
                <TestimonialText>{item.text}</TestimonialText>
              </TestimonialContent>
              <TestimonialAvatar
                src={item.photo}
                name={item.name}
                title={item.title}
              />
            </Testimonial>
          ))}
        </Stack>
      </Container>
    </Box>
  );
}
