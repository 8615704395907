import {
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  VStack,
  List,
  ListItem,
  ListIcon,
  Button,
} from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";
import { Link as ReLink } from "react-router-dom";
import { useUser } from "../../store/userState";

function PriceWrapper({ children }) {
  return (
    <Box
      mb={4}
      shadow="md"
      borderWidth="1px"
      alignSelf={{ base: "center", lg: "flex-start" }}
      borderRadius={"lg"}>
      {children}
    </Box>
  );
}

export default function ThreeTierPricing() {
  const { user } = useUser();
  if (user) return <></>;
  return (
    <Box py={12}>
      <VStack spacing={2} textAlign="center">
        <Heading
          fontSize={{ base: "xl", lg: "2xl", xl: "3xl", "2xl": "4xl" }}
          as={"h2"}>
          12-Week ultimate fat loss programme
        </Heading>
      </VStack>
      <Stack
        direction={{ base: "column", md: "row" }}
        textAlign="center"
        justify="center"
        spacing={{ base: 4, lg: 10 }}
        py={10}>
        <PriceWrapper>
          <Box py={4} px={12}>
            <Text fontWeight="500" fontSize="2xl">
              Personal Guidance
            </Text>
            <HStack justifyContent="center">
              <Text fontSize="3xl" fontWeight="600">
                ₹
              </Text>
              <Text fontSize="5xl" fontWeight="900">
                7350
              </Text>
            </HStack>
            <Text fontSize="xl" fontWeight="500">
              <Text
                as={"span"}
                textDecoration={"line-through"}
                color={"red.500"}>
                ₹ 21000
              </Text>
              <Text as={"span"} pl={2}>
                65% Off
              </Text>
            </Text>
          </Box>
          <VStack bg={"gray.50"} py={4} borderBottomRadius={"xl"}>
            <List spacing={3} textAlign="start" px={12}>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Personalised Meal Plan
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Daily Support From Mr. Vijay and his team
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Personalized Workout Program
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Like-Minded Private Group
              </ListItem>
            </List>
            <Box w="80%" pt={7}>
              <Button
                w="full"
                colorScheme="red"
                as={ReLink}
                to={"/auth/signup"}>
                Sign up now
              </Button>
            </Box>
          </VStack>
        </PriceWrapper>
      </Stack>
    </Box>
  );
}
