import React, { useLayoutEffect } from "react";
import {
  Box,
  Button,
  Drawer,
  DrawerContent,
  DrawerBody,
  DrawerCloseButton,
  DrawerHeader,
  useDisclosure,
  useTheme,
  Image,
  Flex,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import LogoImage from "../../asset/image/logo.png";
import SidebarContent from "./SidebarContent";
import ConsoleTopNav from "./ConsoleTopNav";
import { Link as ReLink } from "react-router-dom";
import { useUser } from "../../store/userState";

export default function Layout({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const theme = useTheme();
  useLayoutEffect(() => onClose(), [location, onClose]);
  const { user } = useUser();

  return (
    <>
      <Box minH="100vh" bg={theme.colors.brand.bg}>
        <Box
          transition="3s ease"
          bg={"white"}
          borderRight="1px"
          borderRightColor={"slate.200"}
          display={{ base: "none", md: "block" }}
          w={60}
          pos="fixed"
          h="full">
          <Flex
            h="20"
            alignItems="center"
            mx="8"
            justifyContent="space-between">
            <ReLink to={"/"}>
              <Image src={LogoImage} />
            </ReLink>
          </Flex>
          <SidebarContent />
        </Box>

        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>
              <Image src={LogoImage} />
            </DrawerHeader>

            <DrawerBody p={0}>
              <SidebarContent />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
        <ConsoleTopNav onOpen={onOpen} />
        <Box ml={{ base: 0, md: 60 }}>{children}</Box>
      </Box>
      {!(user?.dob && user?.height) && (
        <Box bg={"red.100"} bottom={0} position={"fixed"}>
          <Stack
            spacing={4}
            w={"100vw"}
            p={2}
            alignItems={"center"}
            justifyContent={"center"}
            direction={{ base: "column", md: "row" }}>
            <Text textAlign={"center"}>
              Please Update your date of birth &amp; height to continue
            </Text>
            <Button
              as={ReLink}
              to="/console/profile"
              colorScheme={"red"}
              rounded={"full"}
              size={"sm"}>
              Update now
            </Button>
          </Stack>
        </Box>
      )}
    </>
  );
}
