import { Route, Routes, Navigate } from "react-router-dom";
import Signin from "./Signin";
import Signup from "./Signup";
import ForgotPassword from "./Forgot";
import ResetPassword from "./ResetPassword";
import Header from "../components/shared/Header";

import { Box } from "@chakra-ui/react";
const AppRouter = () => {
  return (
    <Box bg="brand.bg" minH={"100vh"}>
      <Header />
      <Routes>
        <Route path="/signin" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route path="/reset" element={<ResetPassword />} />
        <Route path="/*" element={<Navigate to="/auth/signin" />} />
      </Routes>
    </Box>
  );
};

export default AppRouter;
