import React from "react";
import {
  HStack,
  Stack,
  Button,
  Container,
  Heading,
  Select,
  FormControl,
  Input,
  FormLabel,
  FormErrorMessage,
  useTheme,
} from "@chakra-ui/react";
import HeightInput from "../components/shared/HeightInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import CmInchInput from "../components/shared/CmInchInput";
import WeightInput from "../components/shared/WeigtInput";

const CalculatorForm = ({ onSubmit }) => {
  const theme = useTheme();
  const formik = useFormik({
    initialValues: {
      age: "",
      gender: "",
      height: "",
      weight: "",
      neck: "",
      waist: "",
      hip: "",
    },
    validationSchema: new Yup.object({
      age: Yup.number()
        .typeError("Please enter valid age")
        .required("Please enter your age")
        .min(12, "Minimum age should be 12")
        .max(85, "Maximum age should be 85"),
      gender: Yup.string().trim().required("Please select gender"),
      height: Yup.number()
        .required("Please enter height")
        .min(120, "Minimum 120cm")
        .max(220, "Maximum 220cm"),
      weight: Yup.number()
        .required("Please enter weight")
        .min(20, "Invalid weight")
        .max(250, "Invalid weight"),
      neck: Yup.number()
        .required("Please enter neck")
        .min(13, "Invalid neck size")
        .max(55, "Invalid neck size"),
      waist: Yup.number()
        .required("Please enter waist")
        .min(50, "Invalid waist size")
        .max(130, "invalid waist size"),
      hip: Yup.number()
        .when("gender", {
          is: (val) => val === "female",
          then: (s) => s.required("Please enter hip"),
          otherwise: (s) => s.optional(),
        })
        .typeError("Invalid hip")
        .min(50, "Invalid waist size")
        .max(130, "invalid waist size"),
    }),
    onSubmit: (values, { setSubmitting }) => {
      onSubmit({
        age: +values.age,
        gender: values.gender,
        height: +values.height,
        weight: +values.weight,
        neck: +values.neck,
        waist: +values.waist,
        hip: +values.hip,
      });
      setSubmitting(false);
    },
  });
  return (
    <Container maxW={"lg"} py={16}>
      <Heading fontSize={"xl"} textAlign={"center"} pb={6}>
        Fitness Calculator
      </Heading>

      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={4}>
          <HStack spacing={4}>
            <FormControl isInvalid={!!formik.errors.age && formik.touched.age}>
              <FormLabel fontSize={"sm"}>Age</FormLabel>
              <Input
                name={"age"}
                onChange={formik.handleChange}
                value={formik.values.age}
                borderColor="slate.200"
                _focus={{
                  borderColor: "brand.primary",
                  boxShadow: `0 0 0 1px ${theme.colors.brand.primary}`,
                }}
                placeholder="Age"
                _palceholder={{ color: theme.colors.slate["500"] }}
              />
              <FormErrorMessage>{formik.errors.age}</FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={!!formik.errors.gender && formik.touched.gender}>
              <FormLabel fontSize={"sm"}>Gender</FormLabel>
              <Select
                name="gender"
                onChange={formik.handleChange}
                value={formik.values.gender}
                borderColor="slate.200"
                _focus={{
                  borderColor: "brand.primary",
                  boxShadow: `0 0 0 1px ${theme.colors.brand.primary}`,
                }}
                placeholder="Gender"
                _palceholder={{ color: theme.colors.slate["500"] }}>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </Select>
              <FormErrorMessage>{formik.errors.gender}</FormErrorMessage>
            </FormControl>
          </HStack>
          <Stack direction={{ base: "column", sm: "row" }}>
            <WeightInput name="weight" label="Weight" formik={formik} />
            <CmInchInput name="neck" label="Neck" formik={formik} />
          </Stack>
          <Stack direction={{ base: "column", sm: "row" }}>
            <CmInchInput name="waist" label="Waist" formik={formik} />
            {formik.values.gender === "female" && (
              <CmInchInput name="hip" label="Hip" formik={formik} />
            )}
          </Stack>
          <HeightInput name="height" formik={formik} />
          <Button
            isLoading={formik.isSubmitting}
            loadingText="Submitting"
            size="md"
            variant="solid"
            type="submit"
            fontWeight={"500"}
            bg={"brand.accent"}
            color="white"
            _hover={(theme) => ({
              background: theme.colors.brand.accentDark,
            })}
            _active={(theme) => ({
              background: theme.colors.brand.accentDark,
            })}>
            Calculate
          </Button>
        </Stack>
      </form>
    </Container>
  );
};

export default CalculatorForm;
