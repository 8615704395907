import React, { useMemo, useState } from "react";
import { Activities } from "./constants";
import { calorieNeeds } from "./fitness-calculator-free";
import {
  Heading,
  Select,
  FormControl,
  FormLabel,
  VStack,
  Text,
  HStack,
  Container
} from "@chakra-ui/react";

const Calories = ({ values }) => {
  const [activity, setActivity] = useState(Activities[0].value);

  const data = useMemo(() => {
    const age = +values.age,
      height = +values.height,
      weight = +values.weight,
      gender = values.gender;
    return calorieNeeds(gender, age, height, weight, activity);
  }, [values, activity]);

  return (
      <Container maxW={"lg"} py={2}>
      <Heading
        as={"h4"}
        fontSize={"lg"}
        textAlign={"center"}
        my={3}
        fontWeight={"bold"}
        color={"brand.primary"}>
        Recommended calories per day
      </Heading>

      <FormControl>
        <FormLabel fontSize={"sm"}>
          Activity type
        </FormLabel>
        <Select
          borderColor="gray.200"
          _focus={(theme) => ({
            borderColor: "brand.primary",
            boxShadow: `0 0 0 1px ${theme.colors.brand["primary"]}`,
          })}
          defaultValue={activity}
          onChange={(e) => setActivity(e.target.value)}>
          {Activities.map((i) => (
            <option key={i.value} value={i.value}>
              {i.label}
            </option>
          ))}
        </Select>
      </FormControl>

      <VStack py={4} spacing={4} fontWeight={"semibold"}>
        <HStack w={"full"} align={"flex-start"}>
          <Text flex={1}>Maintain weight</Text>
          <Text color={"brand.primary"}>{data.balance}</Text>
        </HStack>
        <HStack w={"full"} align={"flex-start"}>
          <Text flex={1}>
            Mild Weight Loss{" "}
            <Text as={"span"} color={"gray.500"} whiteSpace={"nowrap"}>
              (0.25 kg/week Approx.)
            </Text>
          </Text>
          <Text color={"brand.primary"}>{data.mildWeightLoss}</Text>
        </HStack>
        <HStack w={"full"} align={"flex-start"}>
          <Text flex={1}>
            Heavy Weight Loss{" "}
            <Text as={"span"} color={"gray.500"} whiteSpace={"nowrap"}>
              (0.50 kg/week Approx.)
            </Text>
          </Text>
          <Text color={"brand.primary"}>{data.heavyWeightLoss}</Text>
        </HStack>
        <HStack w={"full"} align={"flex-start"}>
          <Text flex={1}>
            Extream Weight Loss{" "}
            <Text as={"span"} color={"gray.500"} whiteSpace={"nowrap"}>
              (0.75 kg/week Approx.)
            </Text>
          </Text>
          <Text color={"brand.primary"}>{data.extreamWeightLoss}</Text>
        </HStack>
      </VStack>
    </Container>
  );
};

export default Calories;
