import React from "react";
import Header from "../components/shared/Header";
import Footer from "../components/shared/Footer";
import { Container, Heading, Text, Box, Button, Flex } from "@chakra-ui/react";
import { useSearchParams, Link } from "react-router-dom";
import { CheckCircleIcon, CloseIcon } from "@chakra-ui/icons";

const PaymentStatus = () => {
  const [searchParams] = useSearchParams();
  const status = searchParams.get("payment_status");

  if (status === "Credit")
    return (
      <Layout>
        <Success />
      </Layout>
    );
  return (
    <Layout>
      <Error />
    </Layout>
  );
};

const Layout = ({ children }) => (
  <Box>
    <Header />
    <Container maxW={"4xl"} py={6} minH={"80vh"}>
      {children}
    </Container>
    <Footer />
  </Box>
);

function Success() {
  return (
    <Box textAlign="center" py={10} px={6}>
      <CheckCircleIcon boxSize={"50px"} color={"green.500"} />
      <Heading as="h2" size="lg" mt={6} mb={2}>
        Payment Successful
      </Heading>
      <Text color={"gray.500"} py={6}>
        Congratulations! We're happy to have you as a member of the TDS Family.
      </Text>

      <Button as={Link} to={"/auth/login"} colorScheme={"red"}>
        Click here to Login
      </Button>
    </Box>
  );
}

function Error() {
  return (
    <Box textAlign="center" py={10} px={6}>
      <Box display="inline-block">
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          bg={"red.500"}
          rounded={"50px"}
          w={"55px"}
          h={"55px"}
          textAlign="center">
          <CloseIcon boxSize={"20px"} color={"white"} />
        </Flex>
      </Box>
      <Heading as="h2" size="xl" mt={6} mb={2}>
        Payment Failed
      </Heading>
      <Text color={"gray.500"} py={6}>
        Sory, We don't want to lose you because something went wrong. Please try
        once more. Contact our support team if you require assistance.
      </Text>
      <Button as={Link} to={"/auth/signup"} colorScheme={"red"}>
        Click here to signup
      </Button>
    </Box>
  );
}

export default PaymentStatus;
// payment_status
