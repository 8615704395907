import React from "react";
import {
  Box,
  Flex,
  IconButton,
  Button,
  HStack,
  useDisclosure,
  Text,
  Image,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerContent,
  DrawerCloseButton,
  DrawerFooter,
  useToast,
  Link,
  Stack,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { FiPower } from "react-icons/fi";
import { Link as ReLink, useNavigate } from "react-router-dom";
import LogoImage from "../../asset/image/logo-white.png";
import LogoBrand from "../../asset/image/logo.png";
import { useUser } from "../../store/userState";
import SidebarContent from "./SidebarContent";
import { signout } from "../../helper/auth";

export default function Navigation() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const { user, setUser } = useUser();
  const toast = useToast();
  const navigate = useNavigate();

  //Duplicated in ConsoleTopNav
  const handleSignout = () => {
    signout(() => {
      toast({
        description: "You have been logged out",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom-right",
      });
      setUser(null);
      navigate("/");
    });
  };

  return (
    <Box bg={"brand.primaryDark"}>
      <Flex
        color={"white"}
        minH={"60px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        maxW="6xl"
        mx="auto"
        align={"center"}
      >
        <Flex ml={{ base: -2 }} display={{ base: "flex", md: "none" }}>
          <IconButton
            onClick={onOpen}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={"ghost"}
            _hover={{ background: "transparent" }}
            _active={{ background: "transparent" }}
          />
        </Flex>

        <HStack
          alignItems={"center"}
          justifyContent={"space-between"}
          spacing={4}
          ml={"auto"}
          w={"full"}
        >
          <ReLink to={"/"}>
            <Image src={LogoImage} w={"120px"} />
          </ReLink>
          {!!user ? (
            <HStack spacing={2} alignItems={"center"}>
              <Text>Hi, {user.name}</Text>
              <Button
                display={{ base: "none", md: "inline-flex" }}
                as={ReLink}
                to={"/console/dashboard"}
                size={"md"}
                fontWeight={400}
                variant={"ghost"}
                _hover={{ background: "transparent" }}
                _active={{ background: "transparent" }}
              >
                Dashboard
              </Button>
              <Button
                display={{ base: "none", md: "inline-flex" }}
                size={"md"}
                fontWeight={400}
                variant={"ghost"}
                _hover={{ background: "transparent" }}
                _active={{ background: "transparent" }}
                onClick={handleSignout}
              >
                Sign Out
              </Button>
            </HStack>
          ) : (
            <>
              <Stack direction={{ sm: "column", lg: "row" }} gap={1}>
                <Button
                  as={ReLink}
                  size={"md"}
                  fontWeight={600}
                  variant={"ghost"}
                  _hover={(theme) => ({
                    background: "transparent",
                    color: theme.colors.brand.accent,
                    textDecoration: "none",
                  })}
                  _active={(theme) => ({
                    background: "transparent",
                    color: theme.colors.brand.accent,
                    textDecoration: "none",
                  })}
                  to={"/calculator"}
                >
                  Free Fitness Calc
                </Button>
                <Button
                  as={Link}
                  size={"md"}
                  fontWeight={600}
                  variant={"ghost"}
                  _hover={(theme) => ({
                    background: "transparent",
                    color: theme.colors.brand.accent,
                    textDecoration: "none",
                  })}
                  _active={(theme) => ({
                    background: "transparent",
                    color: theme.colors.brand.accent,
                    textDecoration: "none",
                  })}
                  href={"https://fitness.tamildietstudio.com/basic"}
                >
                  BMI &amp; BMR Calc
                </Button>
              </Stack>
              <Button
                as={ReLink}
                to={"/auth/signin"}
                size={"md"}
                fontWeight={500}
                color={"white"}
                bg={"brand.accent"}
                rounded="full"
                _hover={{
                  bg: "brand.accentDark",
                }}
                _active={{
                  bg: "brand.accentDark",
                }}
              >
                Log in
              </Button>
            </>
          )}
        </HStack>
      </Flex>

      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        finalFocusRef={btnRef}
      >
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Image src={LogoBrand} />
          </DrawerHeader>

          <DrawerBody p={0}>
            <SidebarContent />
          </DrawerBody>
          <DrawerFooter justifyContent={"flex-start"}>
            {!!user && (
              <Button
                leftIcon={<FiPower />}
                fontWeight={"500"}
                variant="ghost"
                onClick={handleSignout}
              >
                Signout
              </Button>
            )}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
}
