import React from "react";
import Header from "../components/shared/Header";
import Footer from "../components/shared/Footer";
import { Container, Heading, Text, Box, Link } from "@chakra-ui/react";

const CalculatorIndex = () => {
  return (
    <Box bg={"gray.50"}>
      <Header />
      <Container maxW={"4xl"} py={6}>
        <Heading as={"h3"} fontSize={"2xl"} textAlign={"center"} py={8}>
          Privacy Policy &amp; Terms of use
        </Heading>
        <Text pb={4}>
          We value the trust you place in us and recognize the importance of
          secure transactions and information privacy. This Privacy Policy
          describes how TDSPro collect, use, share or otherwise process your
          personal information through website www.tdspro.in, its and m-site
          (hereinafter referred to as the “Platform”).
        </Text>

        <Text pb={4}>
          While you may be able to browse certain sections of the Platform
          without registering with us, however, please note we offer service
          under this Platform in and outside India. By visiting this Platform,
          providing your information or availing out service, you expressly
          agree to be bound by the terms and conditions of this Privacy Policy,
          the Terms of Use and the applicable service terms and conditions, and
          agree to be governed by the laws of India including but not limited to
          the laws applicable to data protection and privacy. If you do not
          agree please do not use or access our Platform.
        </Text>

        <Heading as={"h4"} fontSize={"lg"} py={2}>
          1. Collection of Your Information
        </Heading>
        <Text pb={4}>
          When you use our Platform, we collect and store your information which
          is provided by you from time to time. In general, you can browse the
          Platform without telling us who you are or revealing any personal
          information about yourself. Once you give us your personal
          information, you are not anonymous to us. Where possible, we indicate
          which fields are required and which fields are optional. You always
          have the option to not provide information by choosing not to use a
          particular service, product or feature on the Platform.
        </Text>

        <Text pb={4}>
          We collect personal information (such as email address, name, phone
          number, date of birth, weight and few other body mesurements) from you
          when you set up an account. While you can browse some sections of our
          Platform without being a registered member, certain activities (such
          as consuming our online content or services) do require registration.
          We do use your contact information to send you offers based on your
          previous orders and your interests.
        </Text>

        <Heading as={"h4"} fontSize={"lg"} py={2}>
          2. Use of Profile Data / Your Information
        </Heading>
        <Text pb={4}>
          We use your personal information to provide the services you request.
          We use your personal information to inform you about online and
          offline offers, products, services, and updates; detect and protect us
          against error, fraud and other criminal activity; enforce our terms
          and conditions; and as otherwise described to you at the time of
          collection of information.
        </Text>

        <Text pb={4}>
          We will NOT ACCESS to your SMS, contacts in your directory, location
          and device information.
        </Text>

        <Text pb={4}>
          In our efforts to continually improve our product and service
          offerings, we and our affiliates collect and analyse demographic and
          profile data about our users’ activity on our Platform. We identify
          and use your IP address to help diagnose problems with our server, and
          to administer our Platform. Your IP address is also used to help
          identify you and to gather broad demographic information.
        </Text>

        <Heading as={"h4"} fontSize={"lg"} py={2}>
          3. Cookies
        </Heading>
        <Text pb={4}>
          We use data collection devices such as "cookies" on certain pages of
          the Platform to help analyse our web page flow, measure promotional
          effectiveness, and promote trust and safety. "Cookies" are small files
          placed on your hard drive that assist us in providing our services.
          Cookies do not contain any of your personal information. We offer
          certain features that are only available through the use of a
          "cookie". We also use cookies to allow you to enter your password less
          frequently during a session. Cookies can also help us provide
          information that is targeted to your interests. Most cookies are
          "session cookies," meaning that they are automatically deleted from
          your hard drive at the end of a session. You are always free to
          decline/delete our cookies if your browser permits, although in that
          case you may not be able to use certain features on the Platform and
          you may be required to re-enter your password more frequently during a
          session.
        </Text>

        <Heading as={"h4"} fontSize={"lg"} py={2}>
          4. Sharing of personal information
        </Heading>
        <Text pb={4}>
          We may disclose your personal information if we are required by law to
          do so. Otherwise we will never share your personal information
        </Text>

        <Heading as={"h4"} fontSize={"lg"} py={2}>
          5. Security Precautions
        </Heading>
        <Text pb={4}>
          We maintain reasonable physical, electronic and procedural safeguards
          to protect your information. Whenever you access your account
          information, we offer the use of a secure server. Once your
          information is in our possession we adhere to our security guidelines
          to protect it against unauthorized access. However, by using the
          Platform, the users accept the inherent security implications of data
          transmission over the internet and the World Wide Web which cannot
          always be guaranteed as completely secure, and therefore, there would
          always remain certain inherent risks regarding use of the Platform.
          Users are responsible for ensuring the protection of login and
          password records for their account.
        </Text>

        <Heading as={"h4"} fontSize={"lg"} py={2}>
          6. Children
        </Heading>
        <Text pb={4}>
          Information We do not knowingly solicit or collect personal
          information from children under the age of 18 and use of our Platform
          is available only to persons who can form a legally binding contract
          under the Indian Contract Act, 1872. If you are under the age of 18
          years then you must use the Platform, application or services under
          the supervision of your parent, legal guardian, or any responsible
          adult.
        </Text>

        <Heading as={"h4"} fontSize={"lg"} py={2}>
          7. Your Consent
        </Heading>
        <Text pb={4}>
          By visiting our Platform or by providing your information, you consent
          to the collection, use, storage, disclosure and otherwise processing
          of your information (including sensitive personal information) on the
          Platform in accordance with this Privacy Policy.
        </Text>

        <Text pb={4}>
          If you disclose to us any personal information relating to other
          people, you represent that you have the authority to do so and to
          permit us to use the information in accordance with this Privacy
          Policy. You, while providing your personal information over the
          Platform or any partner platforms or establishments, consent to us to
          contact you through SMS, instant messaging apps, call and/or e-mail
          for the purposes specified in this Privacy Policy.
        </Text>

        {/* <Heading as={"h4"} fontSize={"lg"} py={2}>
          8. Payments
        </Heading>
        <Text pb={4}>
          We use Instamojo for processing payments. We/Instamojo do not store
          your card data on their servers. The data is encrypted through the
          Payment Card Industry Data Security Standard (PCI-DSS) when processing
          payment. Your payment transaction data is only used as long as is
          necessary to complete your transaction. After that is complete, your
          purchase transaction information is not saved. Our payment gateway
          adheres to the standards set by PCI-DSS as managed by the PCI Security
          Standards Council, which is a joint effort of brands like Visa,
          MasterCard, American Express and Discover. PCI-DSS requirements help
          ensure the secure handling of credit card information by our store and
          its service providers. For more insight, you may also want to read
          terms and conditions of Instamojo on{" "}
          <Link href="https://www.instamojo.com/" isExternal>
            https://www.instamojo.com/
          </Link>
        </Text> */}

        <Heading as={"h4"} fontSize={"lg"} py={2}>
          8. Payments
        </Heading>
        <Text pb={4}>
          We use Razorpay for processing payments. We/Razorpay do not store your
          card data on their servers. The data is encrypted through the Payment
          Card Industry Data Security Standard (PCI-DSS) when processing
          payment. Your purchase transaction data is only used as long as is
          necessary to complete your purchase transaction. After that is
          complete, your purchase transaction information is not saved. Our
          payment gateway adheres to the standards set by PCI-DSS as managed by
          the PCI Security Standards Council, which is a joint effort of brands
          like Visa, MasterCard, American Express and Discover. PCI-DSS
          requirements help ensure the secure handling of credit card
          information by our store and its service providers. For more insight,
          you may also want to read terms and conditions of razorpay on
          https://razorpay.com
        </Text>

        <Heading as={"h4"} fontSize={"lg"} py={2}>
          9. Your Account and Registration Obligations
        </Heading>
        <Text pb={4}>
          If You use the Platform, You shall be responsible for maintaining the
          confidentiality of your Display Name and Password and You shall be
          responsible for all activities that occur under your Display Name and
          Password. You agree that if You provide any information that is
          untrue, inaccurate, not current or incomplete or We have reasonable
          grounds to suspect that such information is untrue, inaccurate, not
          current or incomplete, or not in accordance with the this Terms of
          Use, We shall have the right to indefinitely suspend or terminate or
          block access of your membership on the Platform and refuse to provide
          You with access to the Platform.
        </Text>

        <Text pb={4}>
          Your mobile phone number and/or e-mail address is treated as Your
          primary identifier on the Platform. It is your responsibility to
          ensure that Your mobile phone number and your email address is up to
          date on the Platform at all times. You agree to notify Us promptly if
          your mobile phone number or e-mail address changes by updating the
          same on the Platform through a onetime password verification.
        </Text>

        <Text pb={4}>
          You agree that TDSPro shall not be liable or responsible for the
          activities or consequences of use or misuse of any information that
          occurs under your Account in cases, including, where You have failed
          to update Your revised mobile phone number and/or e-mail address on
          the Website Platform.
        </Text>

        <Text pb={4}>
          If You share or allow others to have access to Your account on the
          Platform (“Account”), by creating separate profiles under Your
          Account, or otherwise, they will be able to view and access Your
          Account information. You shall be solely liable and responsible for
          all the activities undertaken under Your Account, and any consequences
          therefrom.
        </Text>

        <Heading as={"h4"} fontSize={"lg"} py={2}>
          10. Refund Policy
        </Heading>
        <Text pb={4}>
          We deal in digital goods and services. Please make sure that you check
          quality of our free samples and service before availing anything. We
          are not a market place. We control quality very strictly as everything
          is designed inhouse. We don't accept refund request, Late or missing
          refunds (if applicable) Because of digital goods and services, we
          don't accept refund requests, please contact us at support@tdspro.in.
        </Text>

        <Heading as={"h4"} fontSize={"lg"} py={2}>
          11. Changes to this Privacy Policy
        </Heading>
        <Text pb={4}>
          Please check our Privacy Policy periodically for changes. We may
          update this Privacy Policy to reflect changes to our information
          practices. We will alert you to significant changes by posting the
          date our policy got last updated, placing a notice on our Platform, or
          by sending you an email when we are required to do so by applicable
          law.
        </Text>

        <Heading as={"h4"} fontSize={"lg"} py={2}>
          12. Grievance Officer
        </Heading>
        <Text pb={4}>
          In accordance with Information Technology Act 2000 and rules made
          there under, the name and contact details of the Grievance Officer are
          provided below:
        </Text>
        <Text>A. Vijayasankar</Text>
        <Text>
          21, Paramasivam Street, Senthaneer Puram, Trichy, Tamilnadu - 620004
        </Text>
        <Text>Phone: +91 78716 05001</Text>
        <Text>Time: Mon - Sat (9:00 - 18:00)</Text>
      </Container>
      <Footer />
    </Box>
  );
};

export default CalculatorIndex;
