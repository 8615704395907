import React, { useState, useRef } from "react";
import {
  FormControl,
  FormErrorMessage,
  useTheme,
  Radio,
  RadioGroup,
  Box,
  Text,
  Stack,
  InputGroup,
  InputRightAddon,
  NumberInput,
  NumberInputField,
} from "@chakra-ui/react";

function inchToCm(inch) {
  return inch * 2.54;
}

function cmToFeetAndInches(cm) {
  const inches = cm / 2.54;
  const feet = Math.floor(inches / 12);
  const inchesRemaining = inches % 12;
  return { feet, inches: inchesRemaining };
}

const HeightInput = ({ formik, name }) => {
  const [unit, setUnit] = useState("cm");

  return (
    <Box>
      <RadioGroup
        onChange={setUnit}
        value={unit}
        fontWeight={"500"}
        name={"preferedUnit"}>
        <Stack direction={"row"} mb={3} spacing={6}>
          <Text fontSize={"sm"}>Height in</Text>
          <Radio value={"cm"} colorScheme={"purple"} size={"sm"}>
            Centimeters
          </Radio>
          <Radio value={"feet"} colorScheme={"purple"} size={"sm"}>
            Feet
          </Radio>
        </Stack>
      </RadioGroup>
      {unit === "cm" ? (
        <CMInput name={name} formik={formik} />
      ) : (
        <FeetInput name={name} formik={formik} />
      )}
    </Box>
  );
};

export default HeightInput;

const CMInput = ({ name, formik }) => {
  const theme = useTheme();
  return (
    <FormControl isInvalid={!!formik.errors[name] && formik.touched[name]}>
      <InputGroup>
        <NumberInput
          inputMode={"numeric"}
          precision={2}
          step={1}
          name={name}
          type="number"
          value={formik.values[name]}
          onChange={(v) => formik.setFieldValue(name, v)}
          w="full">
          <NumberInputField
            borderColor="slate.200"
            borderRightRadius={0}
            _focus={{
              borderColor: "brand.primary",
              boxShadow: `0 0 0 1px ${theme.colors.brand.primary}`,
            }}
          />
        </NumberInput>
        <InputRightAddon children="cm" />
      </InputGroup>
      <FormErrorMessage>{formik.errors[name]}</FormErrorMessage>
    </FormControl>
  );
};

const FeetInput = ({ name, formik }) => {
  const theme = useTheme();
  const initialFeet = cmToFeetAndInches(formik.values.height || 0);

  const feetRef = useRef(initialFeet.feet);
  const inchRef = useRef(initialFeet.inches);

  const handleChange = () => {
    const feet = +feetRef.current.value;
    const inch = +inchRef.current.value;
    const totalInches = feet * 12 + inch;
    const cm = inchToCm(totalInches);
    formik.setFieldValue(name, cm);
  };

  return (
    <FormControl isInvalid={!!formik.errors[name] && formik.touched[name]}>
      <Stack direction={"row"} spacing={6}>
        <InputGroup>
          <NumberInput
            inputMode={"numeric"}
            precision={0}
            step={1}
            name={"feet"}
            type="number"
            defaultValue={initialFeet.feet}
            onChange={handleChange}>
            <NumberInputField
              ref={feetRef}
              borderColor="slate.200"
              borderRightRadius={0}
              _focus={{
                borderColor: "brand.primary",
                boxShadow: `0 0 0 1px ${theme.colors.brand.primary}`,
              }}
            />
          </NumberInput>
          <InputRightAddon children="Feet" />
        </InputGroup>

        <InputGroup>
          <NumberInput
            inputMode={"numeric"}
            precision={0}
            step={1}
            name={"inch"}
            type="number"
            defaultValue={initialFeet.inches}
            onChange={handleChange}>
            <NumberInputField
              ref={inchRef}
              borderColor="slate.200"
              borderRightRadius={0}
              _focus={{
                borderColor: "brand.primary",
                boxShadow: `0 0 0 1px ${theme.colors.brand.primary}`,
              }}
            />
          </NumberInput>
          <InputRightAddon children={"inch"} />
        </InputGroup>
      </Stack>
      <FormErrorMessage>{formik.errors[name]}</FormErrorMessage>
    </FormControl>
  );
};
