import { Suspense, lazy } from "react";
import { Spinner, Center } from "@chakra-ui/react";
import { Route, Routes, Navigate } from "react-router-dom";
const Dashboard = lazy(() => import("../module/dashboard"));
const Food = lazy(() => import("../module/food"));
const Planner = lazy(() => import("../module/planner"));
const PlannerDetail = lazy(() => import("../module/planner/Detail"));
const SampleDiet = lazy(() => import("../module/samplediet"));
const SampleDietDetail = lazy(() => import("../module/samplediet/Details"));
const Profile = lazy(() => import("../module/Profile"));
const PasswordReset = lazy(() => import("../module/Profile/ChangePassword"));
const Progress = lazy(() => import("../module/progress"));
const ProgressDetail = lazy(() => import("../module/progress/Detail"));
const Video = lazy(() => import("../module/Video"));
const VideoPlayer = lazy(() => import("../module/Video/Player"));
const WorkoutLog = lazy(() => import("../module/workoutlog"));
const WorkoutVideos = lazy(() => import("../module/workoutvideos"));
const FoodRequest = lazy(() => import("../module/food_request"));
const DailyStatus = lazy(() => import("../module/daily_status"));
const Blog = lazy(() => import("../module/blog"));

const Sus = ({ children }) => (
  <Suspense
    fallback={
      <Center h={"40vh"}>
        <Spinner
          thickness="2px"
          speed="0.65s"
          emptyColor="gray.200"
          color="brand.primary"
          size="xl"
        />
      </Center>
    }
  >
    {children}
  </Suspense>
);

const ConsoleRouter = () => {
  return (
    <>
      <Routes>
        <Route
          path="dashboard"
          element={
            <Sus>
              <Dashboard />
            </Sus>
          }
        />

        <Route
          path="profile"
          element={
            <Sus>
              <Profile />
            </Sus>
          }
        />
        <Route
          path="profile/password"
          element={
            <Sus>
              <PasswordReset />
            </Sus>
          }
        />

        <Route
          path="progress"
          element={
            <Sus>
              <Progress />
            </Sus>
          }
        />
        <Route
          path="progress/:id"
          element={
            <Sus>
              <ProgressDetail />
            </Sus>
          }
        />
        <Route
          path="food"
          element={
            <Sus>
              <Food />
            </Sus>
          }
        />
        <Route
          path="workout"
          element={
            <Sus>
              <WorkoutLog />
            </Sus>
          }
        />
        <Route
          path="mydietplan"
          element={
            <Sus>
              <Planner />
            </Sus>
          }
        />
        <Route
          path="mydietplan/:id"
          element={
            <Sus>
              <PlannerDetail />
            </Sus>
          }
        />
        <Route
          path="assigned-diet"
          element={
            <Sus>
              <SampleDiet />
            </Sus>
          }
        />
        <Route
          path="assigned-diet/:id"
          element={
            <Sus>
              <SampleDietDetail />
            </Sus>
          }
        />
        <Route
          path="video"
          element={
            <Sus>
              <Video />
            </Sus>
          }
        />
        <Route
          path="video/:id"
          element={
            <Sus>
              <VideoPlayer />
            </Sus>
          }
        />
        <Route
          path="workoutvideos"
          element={
            <Sus>
              <WorkoutVideos />
            </Sus>
          }
        />
        <Route
          path="foodrequest"
          element={
            <Sus>
              <FoodRequest />
            </Sus>
          }
        />
        <Route
          path="dailystatus"
          element={
            <Sus>
              <DailyStatus />
            </Sus>
          }
        />
        <Route
          path="blog/*"
          element={
            <Sus>
              <Blog />
            </Sus>
          }
        />

        <Route path="*" element={<Navigate to="dashboard" />} />
      </Routes>
    </>
  );
};

export default ConsoleRouter;
