import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Stack,
  Link,
  Button,
  Heading,
  Text,
  useColorModeValue,
  useToast,
  useTheme,
} from "@chakra-ui/react";
import { Link as ReactLink } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { authenticate } from "../helper/auth";
import { useNavigate } from "react-router-dom";

export default function SimpleCard() {
  const toast = useToast();
  const navigate = useNavigate();
  const theme = useTheme();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: new Yup.object({
      email: Yup.string().email("Invalid email").required("Please enter email"),
      password: Yup.string().required("Please enter password"),
    }),
    onSubmit: (values, { setSubmitting }) => {
      const url = `${process.env.REACT_APP_API_URL}/client/auth/signin`;
      axios
        .post(url, values)
        .then((res) => {
          authenticate(res.data, () => {
            navigate("/console");
          });
        })
        .catch((err) => {
          toast({
            title: "Error",
            description: err.response.data.error.message,
            status: "error",
            position: "bottom-right",
          });
          setSubmitting(false);
        });
    },
  });

  return (
    <Flex minH={"80vh"} align={"center"} justify={"center"}>
      <Stack spacing={8} mx={"auto"} w={["xs", "sm", "lg"]} py={12} px={6}>
        <Stack align={"center"}></Stack>
        <form onSubmit={formik.handleSubmit}>
          <Box
            rounded={"lg"}
            bg={useColorModeValue("white", "gray.700")}
            boxShadow={"sm"}
            p={4}>
            <Heading
              fontSize={{ base: "lg", md: "xl" }}
              textAlign={"center"}
              pb={4}>
              Log in
            </Heading>
            <Stack spacing={4}>
              <FormControl
                isInvalid={!!formik.errors.email && formik.touched.email}>
                <FormLabel fontSize={"sm"}>Email address</FormLabel>
                <Input
                  name="email"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  borderColor="slate.200"
                  _focus={{
                    borderColor: "brand.primary",
                    boxShadow: `0 0 0 1px ${theme.colors.brand.primary}`,
                  }}
                />
                <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={!!formik.errors.password && formik.touched.password}>
                <FormLabel fontSize={"sm"}>Password</FormLabel>
                <Input
                  type="password"
                  name="password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  borderColor="slate.200"
                  _focus={{
                    borderColor: "brand.primary",
                    boxShadow: `0 0 0 1px ${theme.colors.brand.primary}`,
                  }}
                />

                <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
              </FormControl>
              <Stack spacing={10}>
                <Stack
                  direction={{ base: "column", sm: "row" }}
                  align={"start"}
                  justify={"space-between"}>
                  <Link
                    color={"brand.primary"}
                    as={ReactLink}
                    fontSize={"sm"}
                    to="/auth/forgot">
                    Forgot password?
                  </Link>
                </Stack>
                <Button
                  isLoading={formik.isSubmitting}
                  loadingText="Submitting"
                  type="submit"
                  variant={"solid"}
                  bg={"brand.primary"}
                  color="white"
                  _hover={(theme) => ({
                    background: theme.colors.brand.primaryDark,
                  })}
                  _active={(theme) => ({
                    background: theme.colors.brand.primaryDark,
                  })}>
                  Log in
                </Button>
              </Stack>
              <Stack pt={6}>
                <Text align={"center"} fontSize={"sm"}>
                  New user?{" "}
                  <Link
                    color={"brand.primary"}
                    as={ReactLink}
                    to="/auth/signup">
                    Sign up
                  </Link>
                </Text>
              </Stack>
            </Stack>
          </Box>
        </form>
      </Stack>
    </Flex>
  );
}
