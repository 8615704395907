import React from "react";
import Layout from "../components/shared/Layout";
import ConsoleRouter from "./ConsoleRouter";

const Console = () => {
  return (
    <Layout>
      <ConsoleRouter />
    </Layout>
  );
};
export default Console;
