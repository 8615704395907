import React from "react";
import axios from "axios";
import { getToken, signout } from "./helper/auth";
import { useToast } from "@chakra-ui/react";

const Interceptor = ({ children }) => {
  const toast = useToast();

  axios.interceptors.request.use(
    function (config) {
      config.headers.Authorization = `Bearer ${getToken()}`;
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      let res = error.response;
      if (res?.status === 401 && res.config && !res.config.__isRetryRequest) {
        signout(() => {
          return (window.location.href = "/");
        });
      }

      if (error.message !== undefined && error.message === "Network Error") {
        toast({
          title: "Network Error",
          description: "Check your network connection",
          status: "error",
          position: "bottom-right",
        });
      }

      return Promise.reject(error);
    }
  );
  return <>{children}</>;
};

export default Interceptor;
